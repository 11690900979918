<template>
  <v-dialog
    v-model="deliverLeadModal"
    max-width="650px"
    @click:outside="closeDeliverLeadModal()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Deliver Lead</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row>
              <v-col cols="12">
                <template>
                  <div>
                    <tiptap-vuetify
                      label="Lead information*"
                      v-model="message"
                      :extensions="extensions"
                    />
                  </div>
                </template>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDeliverLeadModal()"> Close </v-btn>
        <v-btn color="primary" text @click="deliverLead()"> Send </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  History,
} from "tiptap-vuetify";

export default {
  components: { TiptapVuetify },
  data() {
    return {
      extensions: [
        History,
        Bold,
        Italic,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList,
        Link,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Blockquote,
        HardBreak,
      ],
      message: "",
    };
  },
  props: {
    deliverLeadModal: Boolean,
    selectedLead: Object,
  },
  methods: {
    closeDeliverLeadModal() {
      this.$emit("closeDeliverLeadModal");
    },
    async deliverLead() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        const response = await this.$http.post(
          `/leads/${this.selectedLead._id}/deliver`,
          {
            message: this.message,
          }
        );
        this.$emit("leadDelivered", response.data);
      } catch (err) {
        this.$emit("failedDeliveringLead", err);
      }
    },
  },
};
</script>

<style lang="scss">
.tiptap-vuetify-editor .v-card {
  box-shadow: none !important;
  border: 1px solid #eee;
}
</style>