<template>
  <div class="leads">
    <deliver-lead-modal
      :deliver-lead-modal="deliverLeadModal"
      :selected-lead="selectedLeads[0]"
      @closeDeliverLeadModal="closeDeliverLeadModal"
      @failedDeliveringLead="failedDeliveringLead"
      @leadDelivered="leadDelivered"
    ></deliver-lead-modal>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="red darken-2"
        @click="deleteLeads()"
        v-if="selectedLeads && selectedLeads.length"
      >
        <v-icon dark> mdi-delete </v-icon>
      </v-btn>
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="green darken-2"
        @click="deliverLeadModal = true"
        v-if="selectedLeads && selectedLeads.length"
      >
        <v-icon dark> mdi-account-cash-outline </v-icon>
      </v-btn>
    </v-row>
    <v-row align="center">
      <v-col>
        <v-card flat>
          <v-tabs v-model="selectedTab" centered fixed-tabs>
            <v-tab :href="`#tab-undelivered`"> Undelivered Leads </v-tab>
            <v-tab :href="`#tab-delivered`"> Delivered Leads </v-tab>
          </v-tabs>
          <v-tabs-items v-model="selectedTab">
            <v-tab-item value="tab-undelivered">
              <v-data-table
                v-model="selectedLeads"
                class="my-8"
                :headers="headers"
                :items="undeliveredLeads"
                item-key="_id"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :loading="dataLoading"
                show-select
              >
                <template v-slot:item.createdAt="{ value }">
                  <time :title="value" :datetime="value">{{
                    formatDate(value)
                  }}</time>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item value="tab-delivered">
              <v-data-table
                v-model="selectedLeads"
                class="my-8"
                :headers="headers"
                :items="deliveredLeads"
                item-key="_id"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :loading="dataLoading"
                show-select
              >
                <template v-slot:item.createdAt="{ value }">
                  <time :title="value" :datetime="value">{{
                    formatDate(value)
                  }}</time>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import DeliverLeadModal from "@/components/DeliverLeadModal.vue";
import { store } from "@/state/store.js";

export default {
  components: {
    DeliverLeadModal,
  },
  data() {
    return {
      leads: [],
      selectedLeads: [],
      sortBy: "createdAt",
      selectedTab: "tab-undelivered",
      sortDesc: true,
      dataLoading: true,
      deliverLeadModal: false,
      store,
    };
  },
  created() {
    this.loadLeads();
  },
  computed: {
    headers() {
      return [
        {
          text: "Company Name",
          value: "companyName",
        },
        {
          text: "First Name",
          value: "firstName",
        },
        {
          text: "Last Name",
          value: "lastName",
        },
        {
          text: "Email Address",
          value: "email",
        },
        {
          text: "Message",
          value: "message",
        },
        { text: "Date Created", value: "createdAt" },
      ];
    },
    selectedClient() {
      return this.store.selectedClient;
    },
    undeliveredLeads() {
      return this.leads.filter((lead) => !lead.delivered);
    },
    deliveredLeads() {
      return this.leads.filter((lead) => lead.delivered);
    },
  },
  watch: {
    selectedClient() {
      this.loadLeads();
    },
  },
  methods: {
    formatDate(dt) {
      return moment(dt).format("MM/DD/YYYY hh:mm");
    },
    async loadLeads() {
      try {
        const leadsResp = await this.$http.get(
          `/leads?_filter=${this.selectedClient}`
        );

        this.leads = leadsResp.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.dataLoading = false;
      }
    },
    closeDeliverLeadModal() {
      this.deliverLeadModal = false;
    },
    failedDeliveringLead(error) {
      console.log(error);
      this.deliverLeadModal = false;
    },
    leadDelivered() {
      this.deliverLeadModal = false;
      this.loadLeads();
    },
    async deleteLeads() {
      await this.$http.post("/leads/delete", {
        leads: this.selectedLeads,
      });
      this.loadLeads();
    },
  },
};
</script>
